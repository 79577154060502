import React, { useState, useEffect, useCallback } from "react";
import { work1, work2, work3, work4, work5 } from '../assets';

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const images = [work1, work2, work3, work5, work4];

    const handleNext = useCallback(() => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    }, [images.length]);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };


    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNext();
        }, 4000);
        return () => {
            clearInterval(intervalId);
        };
    }, [handleNext]);

    const openModal = () => {
        setIsModalOpen(true);
        document.body.classList.add('overflow-hidden');
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.classList.remove('overflow-hidden');
    };

    useEffect(() => {
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    return (
        <section className="relative w-full max-w-lg mx-auto mb-4 pb-0">
            <h2 className="text-center text-xl font-semibold mb-4">Voitures avant et après nettoyage</h2>

            <div className="flex items-center justify-between relative max-w-lg mx-auto">
                <button
                    onClick={handlePrev}
                    className="bg-gray-800 text-white px-2 py-1 rounded-lg hover:bg-gray-700 focus:outline-none z-10"
                >
                    &#8592;
                </button>

                <div className="overflow-hidden mx-2">
                    <img
                        src={images[currentIndex]}
                        alt={`Carousel ${currentIndex + 1}`}
                        className="w-full h-64 object-cover rounded-lg transition duration-500 ease-in-out transform cursor-pointer"
                        onClick={openModal}
                        style={{ maxWidth: '400px', maxHeight: '250px' }}
                    />
                </div>

                <button
                    onClick={handleNext}
                    className="bg-gray-800 text-white px-2 py-1 rounded-lg hover:bg-gray-700 focus:outline-none z-10"
                >
                    &#8594;
                </button>

            </div>

            <div className="flex justify-center mt-2">
                {images.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                        className={`w-3 h-3 mx-1 rounded-full ${currentIndex === index ? "bg-blue-600" : "bg-gray-400"
                            }`}
                    />
                ))}
            </div>

            <p className="text-center text-sm text-gray-600 mt-4">
                Cliquez sur l'image pour la voir en grand.
            </p>

            {isModalOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50"
                    onClick={closeModal} // Close the modal when clicking outside
                >
                    <div
                        className="relative"
                        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on the image
                    >
                        <img
                            src={images[currentIndex]}
                            alt={`Enlarged Carousel ${currentIndex + 1}`}
                            className="object-contain w-full h-auto max-w-full max-h-full md:max-w-4xl md:max-h-[80vh] rounded-lg"
                            style={{ maxWidth: '90vw', maxHeight: '90vh' }}
                        />
                        <button
                            onClick={closeModal}
                            className="absolute top-2 right-2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 focus:outline-none"
                        >
                            ✕
                        </button>

                        <button
                            onClick={handlePrev}
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-4 rounded-full hover:bg-gray-700 focus:outline-none"
                        >
                            ◀
                        </button>

                        <button
                            onClick={handleNext}
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-4 rounded-full hover:bg-gray-700 focus:outline-none"
                        >
                            ▶
                        </button>
                    </div>
                </div>
            )}


        </section>
    );
};

export default Carousel;
