import React from 'react';

const ServiceCard = ({ title, icon }) => {
  return (
    <div className="relative overflow-hidden rounded-lg shadow-lg bg-white hover:shadow-xl transition-shadow duration-300">
      <div className="relative">
        <img src={icon} alt={`${title}`} className="w-full h-60 object-cover" />
        <div className="absolute inset-0 bg-black bg-opacity-30 flex flex-col items-center justify-center">
          <h3 className="text-2xl font-bold text-white mb-4">{title}</h3>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
