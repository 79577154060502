import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Services from './pages/Services';
import Pricing from './pages/Pricing';
import Carousel from './components/Carousel';
import Album from './pages/Album';

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <div className="flex-grow pt-6">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <section id="hero" className="min-h-screen">
                    <Hero />
                  </section>
                  <section id="services" className="min-h-[500px] bg-gwhite">
                    <Services />
                  </section>

                  <section id="pricing" className="min-h-screen bg-white pt-16">
                    <Pricing />
                  </section>
                  <section id="carousel" className="min-h-[400px] pt-8 bg-white">
                    <Carousel />
                  </section>
                </>
              }
            />
            <Route path="/album" element={<Album />} />

            <Route path="/bmw" element={<Album brand="BMW" />} />
            <Route path="/fiat" element={<Album brand="Fiat" />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
