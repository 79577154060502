import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { work1, work2, work3, work4, work5, work6, work7, work8, work9, work10, work11, work12, work13, work14, work15, work16, work17 } from '../assets'; // Import images

const brandItems = {
    BMW: [
        { type: 'image', src: work1 },
        { type: 'image', src: work2 },
        { type: 'image', src: work3 },
        { type: 'image', src: work4 },
        { type: 'image', src: work5 },
        { type: 'image', src: work6 },
        { type: 'image', src: work7 },
    ],
    Fiat: [
        { type: 'image', src: work11 },
        { type: 'image', src: work12 },
        { type: 'image', src: work13 },
        { type: 'image', src: work8 },
        { type: 'image', src: work9 },
        { type: 'image', src: work10 },
        { type: 'image', src: work14 },
        { type: 'image', src: work15 },
        { type: 'image', src: work16 },
        { type: 'image', src: work17 },
    ],
};

const Album = () => {
    const [brand, setBrand] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/album') {
            setBrand(null);
        }
    }, [location]);

    const items = brand ? brandItems[brand] : [];
    const itemsPerPage = 6;
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedItems = items.slice(startIndex, startIndex + itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentImageIndex(null);
    };

    const showNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex + 1 === items.length ? 0 : prevIndex + 1
        );
    };

    const showPrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? items.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="bg-gray-100 min-h-screen py-12">
            <main className="container mx-auto px-4 lg:px-0">
                {!brand ? (
                    <>
                        <h1 className="text-5xl font-extrabold text-center text-gray-800 my-12">
                            Album
                        </h1>
                        <div className="flex justify-center space-x-8">
                            <div
                                onClick={() => setBrand("BMW")}
                                className="cursor-pointer"
                            >
                                <img
                                    src={work6}
                                    alt="BMW"
                                    className="rounded-lg shadow-lg hover:scale-105 transition-transform w-full h-48 object-cover"
                                />
                                <p className="text-center text-xl mt-4 font-bold text-gray-700">
                                    BMW
                                </p>
                            </div>
                            <div
                                onClick={() => setBrand("Fiat")}
                                className="cursor-pointer"
                            >
                                <img
                                    src={work14}
                                    alt="Fiat"
                                    className="rounded-lg shadow-lg hover:scale-105 transition-transform w-full h-48 object-cover"
                                />
                                <p className="text-center text-xl mt-4 font-bold text-gray-700">
                                    Fiat
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className="text-5xl font-extrabold text-center text-gray-800 my-12">
                            {brand}
                        </h1>

                        {items.length > 0 ? (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                                    {selectedItems.map((item, index) => (
                                        <div
                                            key={index}
                                            className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 cursor-pointer"
                                            onClick={() => openModal(startIndex + index)}
                                        >
                                            <img
                                                src={item.src}
                                                alt={`Work ${index + 1}`}
                                                className="w-full h-64 object-cover rounded-lg"
                                            />
                                        </div>
                                    ))}
                                </div>

                                <div className="flex justify-center mt-16 space-x-4">
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className="px-6 py-2 bg-blue-500 text-white rounded-lg font-semibold hover:bg-blue-600 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        Précédent
                                    </button>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === totalPages}
                                        className="px-6 py-2 bg-blue-500 text-white rounded-lg font-semibold hover:bg-blue-600 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        Suivant
                                    </button>
                                </div>

                                <p className="text-center mt-4 text-gray-600 text-lg">
                                    Page {currentPage} of {totalPages}
                                </p>
                            </>
                        ) : (
                            <p className="text-center text-xl text-gray-500">No items available for {brand}.</p>
                        )}

                        {isModalOpen && currentImageIndex !== null && (
                            <div
                                className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                                onClick={closeModal}
                            >
                                <div
                                    className="relative"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {/* Close Button */}
                                    <button
                                        className="absolute top-4 right-4 text-white text-2xl font-bold"
                                        onClick={closeModal}
                                    >
                                        &times;
                                    </button>

                                    {/* Previous Button */}
                                    <button
                                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-3xl font-bold"
                                        onClick={showPrevImage}
                                    >
                                        &#8249;
                                    </button>

                                    {/* Next Button */}
                                    <button
                                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-3xl font-bold"
                                        onClick={showNextImage}
                                    >
                                        &#8250;
                                    </button>

                                    {/* Enlarged Image */}
                                    <img
                                        src={items[currentImageIndex].src}
                                        alt={`Enlarged Work ${currentImageIndex + 1}`}
                                        className="max-w-[90vw] max-h-[80vh] w-auto h-auto rounded-lg"
                                    />
                                </div>
                            </div>
                        )}

                        <div className="flex justify-center mt-12">
                            <button
                                onClick={() => setBrand(null)}
                                className="px-6 py-4 bg-gray-500 text-white rounded-lg font-semibold hover:bg-gray-600 transition-all"
                            >
                                Retour à la sélection de marques
                            </button>
                        </div>
                    </>
                )}
            </main>
        </div>
    );
};

export default Album;
