import React from 'react';
import ServiceCard from '../components/ServiceCard'; 
import { clean, clean2, clean3, clean4, clean5, clean6 } from "../assets";

const services = [
  {
    title: 'Nettoyage des sièges',
    icon: clean,
  },
  {
    title: 'Nettoyage Intérieur',
    icon: clean2,
  },
  {
    title: 'Polissage des Phares',
    icon: clean3,
  },
  {
    title: 'Lavage Extérieur',
    icon: clean6,
  },
  {
    title: 'Lavage de Moteur',
    icon: clean4,
  },
  {
    title: 'Nettoyage des Transports Publics',
    icon: clean5,
  },
];

const Services = () => {
  return (
    <section className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h2 className="text-2xl font-semibold text-center text-gray-800 sm:text-3xl lg:text-4xl">
        Nos prestations
      </h2>
      <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div 
            key={index} 
            className="transform transition duration-500 ease-in-out hover:scale-105 hover:shadow-lg"
          >
            <ServiceCard {...service} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
