import React, { useState, useEffect } from 'react';
import { logo } from "../assets";
import { HashLink } from 'react-router-hash-link';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/album") {
      setActiveLink('album');
    } else if (location.hash === "#services") {
      setActiveLink('services');
    } else if (location.hash === "#pricing") {
      setActiveLink('pricing');
    } else {
      setActiveLink('home');
    }
  }, [location]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-10 transition-all">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-8 w-8 mr-2 rounded-full" />
            <HashLink smooth to="/#home" className="text-xl font-bold text-gray-800">
              Industrial Freitas Clean
            </HashLink>
          </div>

          {/* Mobile menu button */}
          <div className="flex lg:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-gray-800 hover:text-blue-500 focus:outline-none focus:text-blue-500"
            >
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMobileMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
              </svg>
            </button>
          </div>

          {/* Desktop Navbar Links */}
          <div className="hidden lg:flex space-x-4">
            <HashLink smooth to="/#hero" className={`text-gray-600 hover:text-blue-500 ${activeLink === 'home' ? 'text-blue-500 font-bold' : ''}`}>
              Home
            </HashLink>
            <HashLink smooth to="/#services" className={`text-gray-600 hover:text-blue-500 ${activeLink === 'services' ? 'text-blue-500 font-bold' : ''}`}>
              Services
            </HashLink>
            <HashLink smooth to="/#pricing" className={`text-gray-600 hover:text-blue-500 ${activeLink === 'pricing' ? 'text-blue-500 font-bold' : ''}`}>
              Prix
            </HashLink>
            <Link to="/album" className={`text-gray-600 hover:text-blue-500 ${activeLink === 'album' ? 'text-blue-500 font-bold' : ''}`}>
              Album
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden bg-white shadow-md">
          <div className="px-4 pt-2 pb-3 space-y-1">
            <HashLink smooth to="/#hero" className={`block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-blue-500 ${activeLink === 'home' ? 'text-blue-500 font-bold' : ''}`}>
              Home
            </HashLink>
            <HashLink smooth to="/#services" className={`block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-blue-500 ${activeLink === 'services' ? 'text-blue-500 font-bold' : ''}`}>
              Services
            </HashLink>
            <HashLink smooth to="/#pricing" className={`block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-blue-500 ${activeLink === 'pricing' ? 'text-blue-500 font-bold' : ''}`}>
              Prix
            </HashLink>
            <Link to="/album" className={`block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-blue-500 ${activeLink === 'album' ? 'text-blue-500 font-bold' : ''}`}>
              Album
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
